import { Component, OnInit, Input } from '@angular/core';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';

@Component({
  selector: 'app-dirty-message',
  templateUrl: './dirty-message.component.html',
  styleUrls: ['./dirty-message.component.scss'],
})
export class DirtyMessageComponent implements OnInit {
  @Input() conditions: any = '';
  @Input() notConditions = false;
  @Input() dirty: boolean;
  @Input() arr;
  @Input() isClimateArr = false;
  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {}

  isAnyElementDirty() {
    return this.arr.value.some((d) => !d);
  }
}
