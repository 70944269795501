import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'parseInt',
})
export class ParseIntPipe implements PipeTransform {
  transform(a: any): number {
    return parseInt(a, 10);
  }
}
