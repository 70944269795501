import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export enum TypeUser {
  nonVerifiedInstaller = 52,
}

@Injectable({
  providedIn: 'root',
})
export class SessionLibService {
  expired = false;

  // Session API Methods
  // ===================

  public createSession(
    vid: string,
    name: string,
    token: string,
    email: string,
    accessToken: string,
    typeUser: number,
    language: string,
    showAlert: boolean
  ): void {
    localStorage.setItem('vid', vid);
    localStorage.setItem('name', name);
    localStorage.setItem('session_token', token);
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('email', email);
    localStorage.setItem('language', language);
    localStorage.setItem('showAlert', String(showAlert));
  }
  public setShowAlert(showAlert: boolean) {
    localStorage.setItem('showAlert', String(showAlert));
  }
  public getShowAlert() {
    return localStorage.getItem('showAlert') === 'true';
  }
  public setLanguage(language) {
    localStorage.setItem('language', language);
  }
  public getLanguage() {
    let language = localStorage.getItem('language');
    if (
      typeof language !== 'string' ||
      language.trim() === 'null' ||
      language.trim() === '' ||
      language.trim() === null
    ) {
      language = this.getPreferredLanguage();
    }
    return language;
  }
  public setCookiesPolicy(value: string) {
    localStorage.setItem('cookies_policy', value);
  }
  public getCookiesPolicy(): string {
    return localStorage.getItem('cookies_policy');
  }
  public setHome(page) {
    localStorage.setItem('home', page);
  }
  public getHome() {
    return localStorage.getItem('home');
  }
  public updateSession(token: string): void {
    localStorage.setItem('session_token', token);
  }
  public getSuplanted() {
    return localStorage.getItem('suplanted');
  }
  public getName() {
    return localStorage.getItem('name');
  }
  public setSuplanted() {
    localStorage.setItem('suplanted', 'true');
  }
  public setNotSuplanted() {
    localStorage.setItem('suplanted', 'false');
  }
  public destroySession(): void {
    localStorage.removeItem('vid');
    localStorage.removeItem('name');
    localStorage.removeItem('session_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('email');
    localStorage.removeItem('avatar');
    localStorage.removeItem('type');
    if (this.getSuplanted() !== undefined) {
      localStorage.removeItem('suplanted');
    }
    window.location.href = '/login';
  }

  public setAvatar(data): void {
    localStorage.setItem('avatar', JSON.stringify(data));
  }
  public getAvatar(): string {
    return JSON.parse(localStorage.getItem('avatar'));
  }
  public getAccessToken(): string {
    return localStorage.getItem('access_token');
  }
  public getSessionToken(): string {
    return localStorage.getItem('session_token');
  }
  public getSessionEmail(): string {
    return localStorage.getItem('email');
  }
  public getSessionVid(): string {
    return localStorage.getItem('vid');
  }
  public getTypeUser(): TypeUser {
    return Number(localStorage.getItem('type'));
  }
  public sessionTokenIsExpired(expired: boolean): void {
    this.expired = expired;
  }
  public isSessionTokenIsExpired(): boolean {
    return this.expired;
  }
  private getPreferredLanguage(): string {
    let userLang = navigator.language;
    if (typeof userLang === 'string') {
      const userLang_arr = userLang.split('-');
      userLang = userLang_arr[0];
    } else {
      userLang = environment.language;
    }
    return userLang;
  }
}
