import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-ground-triangle',
  templateUrl: './ground-triangle.component.html',
  styleUrls: ['./ground-triangle.component.scss'],
})
export class GroundTriangleComponent implements OnInit, OnChanges {
  @Input() sand: number;
  @Input() clay: number;
  @Input() silt: number;
  @Output() sandChange = new EventEmitter<number>();
  @Output() clayChange = new EventEmitter<number>();
  @Output() siltChange = new EventEmitter<number>();
  img = '';
  @ViewChild('triangle') triangle: ElementRef;
  @ViewChild('point') point: ElementRef;

  ngOnInit(): void {
    switch (environment.language) {
      case 'en':
        this.img = 'assets/img/suelo/Piramide_Suelo_Ucrop_Ing_02.svg';
        break;
      case 'es':
        this.img = 'assets/img/suelo/Piramide_Suelo_Ucrop_Esp_02.svg';
        break;
      case 'fr':
        this.img = 'assets/img/suelo/Piramide_Suelo_Ucrop_Fr_02.svg';
        break;
      case 'pt':
        this.img = 'assets/img/suelo/Piramide_Suelo_Ucrop_Port_02.svg';
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { sand, clay, silt } = changes;
    if (
      (!sand || sand.previousValue === undefined) &&
      (!clay || clay.previousValue === undefined) &&
      (!silt || silt.previousValue === undefined)
    )
      return;
    if (sand && sand.currentValue !== this.sand) this.sand = sand.currentValue;
    if (clay && clay.currentValue !== this.clay) this.clay = clay.currentValue;
    if (silt && silt.currentValue !== this.silt) this.silt = silt.currentValue;
    if (this.sand + this.clay + this.silt === 100) this.updatePoint();
    else this.point.nativeElement.style.display = 'none';
  }

  movePoint(event: MouseEvent) {
    const { x, y } = this.getTapPosition100(event);
    const { sand, clay, silt } = this.getArcillaLimoArena(x, y);
    if (sand < 0 || clay < 0 || silt < 0) return;
    this.sand = Math.round(sand);
    this.clay = Math.round(clay);
    this.silt = Math.round(silt);
    if (this.sand + this.clay + this.silt === 99) this.silt += 1;
    if (this.sand + this.clay + this.silt === 101) this.silt -= 1;
    this.updatePoint();
  }

  getTapPosition100(event) {
    const rect = event.target.getBoundingClientRect();
    let x = 0;
    let y = 0;
    x = event.clientX - rect.left;
    y = event.clientY - rect.top;
    const tile = this.triangle.nativeElement.firstChild.height / 100;
    x = Math.round(x / tile);
    y = 100 - Math.round(y / tile);
    return { x, y };
  }

  getArcillaLimoArena(x, y) {
    const clay = (2 * y) / Math.sqrt(3);
    const sand = 100 - x - y / Math.sqrt(3);
    const silt = 100 - clay - sand;
    return { clay, silt, sand };
  }

  updatePoint() {
    const { width, height } = this.triangle.nativeElement.firstChild;
    const pointSize = 6; // Tamaño del punto puesto en css 6px
    const h = (Math.sqrt(3) * this.clay) / 2;
    const x = (width * (this.silt + this.clay / 2)) / 100 - pointSize;
    const y = (height * (100 - h)) / 100 - pointSize;
    this.point.nativeElement.style.left = x + 'px';
    this.point.nativeElement.style.top = y + 'px';
    this.point.nativeElement.style.display = 'inherit';
    this.sandChange.emit(this.sand);
    this.clayChange.emit(this.clay);
    this.siltChange.emit(this.silt);
  }
}
