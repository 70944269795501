import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';

@Component({
  selector: 'app-metrics-header',
  templateUrl: './metrics-header.component.html',
  styleUrls: ['./metrics-header.component.scss'],
})
export class MetricsHeaderComponent implements OnInit {
  @Input() graphic: any;
  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {}
}
