import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'inputTime',
})
export class inputTime implements PipeTransform {
  transform(date: string, format: string = 'HH:mm:ss'): string {
    return moment(date).format(format);
  }
}
