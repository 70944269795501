import { Injectable } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
declare let gtag;
/*import {Device} from "@capacitor/device";*/
import { SessionLibService } from './session-lib.service';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class HelpersLibService {
  constructor(
    private ga: GoogleAnalytics,
    private session: SessionLibService,
    private titleService: Title
  ) {}

  getUsersLocale(defaultValue: string): string {
    if (this.session.getLanguage() !== null) {
      return this.session.getLanguage();
    } else {
      if (
        typeof window === 'undefined' ||
        typeof window.navigator === 'undefined'
      ) {
        return defaultValue;
      }
      const wn = window.navigator as any;
      let lang = wn.languages ? wn.languages[0] : defaultValue;
      lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
      const minLang = lang.split('-');
      return minLang[0];
    }
  }
  /*async getMobileLanguage(){
    const lang = (await Device.getLanguageCode()).value;
    const minLang = lang.split('-');
    environment.language = minLang[0];
  }*/

  //ANALYTICS
  initializeApp() {
    this.ga
      .startTrackerWithId('G-BN3LL4Y442')
      .then(() => {
        console.log('Google analytics is ready now');
        // Tracker is ready
        // You can now track pages or set additional information such as AppVersion or UserId
      })
      .catch((e) => console.log('Error starting GoogleAnalytics', e));
  }

  sendEventFieldView(fieldName, vid) {
    gtag('event', 'page_view', {
      View_Field: fieldName + ' - field;vid=' + vid,
    });
    gtag('event', 'page_view_by_type', {
      View_Field: 'Field',
    });
    gtag('event', 'page_view_by_user', {
      View_Field: ' View_field - ' + this.session.getSessionEmail(),
    });
  }

  sendEventFieldEdit(fieldName, vid) {
    gtag('event', 'page_view', {
      Edit_Field: fieldName + ' - field;vid=' + vid,
    });
    gtag('event', 'page_view_by_type', {
      Edit_Field: 'Field',
    });
    gtag('event', 'page_view_by_user', {
      Edit_Field: ' View_field - ' + this.session.getSessionEmail(),
    });
  }
  sendEventFieldCreate() {
    gtag('event', 'page_view', {
      Create_Field: 'Create',
    });
    gtag('event', 'page_view_by_type', {
      Create_Field: 'Field',
    });
    gtag('event', 'page_view_by_user', {
      Create_Field: ' View_field - ' + this.session.getSessionEmail(),
    });
  }

  sendEventTerminalView(terminalName, vid) {
    gtag('event', 'page_view', {
      View_Terminal: terminalName + ' - field;vid=' + vid,
    });
    gtag('event', 'page_view_by_type', {
      View_Terminal: 'Terminal',
    });
    gtag('event', 'page_view_by_user', {
      View_Terminal: ' View_Terminal - ' + this.session.getSessionEmail(),
    });
  }

  sendEventTerminalEdit(terminalName, vid) {
    gtag('event', 'page_view', {
      Edit_Terminal: terminalName + ' - field;vid=' + vid,
    });
    gtag('event', 'page_view_by_type', {
      Edit_Terminal: 'Terminal',
    });
    gtag('event', 'page_view_by_user', {
      Edit_Terminal: ' Edit_Terminal - ' + this.session.getSessionEmail(),
    });
  }

  sendEventTerminalPair() {
    gtag('event', 'page_view', {
      Pair_Terminal: 'Pair_terminal',
    });
    gtag('event', 'page_view_by_type', {
      Pair_Terminal: 'Terminal',
    });
    gtag('event', 'page_view_by_user', {
      Pair_Terminal: ' Pair_Terminal - ' + this.session.getSessionEmail(),
    });
  }

  sendEventPageView(page) {
    gtag('event', 'page_view', {
      page_title: page + '',
    });
    gtag('event', 'page_view_by_user', {
      [page]: page + ' - ' + this.session.getSessionEmail(),
    });
  }
  sendEventTerminalCalendarView(terminalName, terminalVid) {
    gtag('event', 'page_view', {
      Calendar_Terminal:
        terminalName + ' - terminal-calendar;vid=' + terminalVid,
    });
    gtag('event', 'page_view_by_type', {
      Calendar_Terminal: 'Calendar_terminal',
    });
    gtag('event', 'page_view_by_user', {
      Calendar_Terminal:
        ' Calendar_terminal - ' + this.session.getSessionEmail(),
    });
  }
  setTitle(pagina, nombre, modo) {
    if (modo != null) {
      if (nombre.includes(pagina)) {
        this.titleService.setTitle('UCROP | ' + modo + ' | ' + nombre);
      } else {
        this.titleService.setTitle(
          'UCROP | ' + modo + ' | ' + pagina + ': ' + nombre
        );
      }
    } else {
      if (nombre.includes(pagina)) {
        this.titleService.setTitle('UCROP | ' + nombre);
      } else {
        this.titleService.setTitle('UCROP | ' + pagina + ': ' + nombre);
      }
    }
  }
  focusTab(tab) {
    for (let x = 50; x <= 2500; x = x + 500) {
      setTimeout(() => {
        tab.focusTab(0);
        tab.realignInkBar();
      }, x);
    }
  }
  validateEmail(email): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
}
